<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Penerima Donor'">
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <Form
              :form="form"
              :route="'donor-recipients/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/donor-recipients/FormEdit.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: "",
        blood_type_id: null,
        age: null,
        last_donation_date: new Date().toISOString().substr(0, 10),
        phone: "",
        gender: null,
        email: "",
      },
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('donor-recipients/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/donor-recipients')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bank Darah", route: "/blood-bank" },
      { title: "Daftar Penerima Donor", route: "/donor-recipients" },
      { title: "Edit Penerima Donor" },
    ])
    // Get Data
    this.get()
  },

}

</script>